import * as actionTypes from './actionTypes';

export function showControls() {
    return { type: actionTypes.CONTROLS_SHOW };
}

export function hideControls() {
    return { type: actionTypes.CONTROLS_HIDE };
}

export function playVideo() {
    return { type: actionTypes.VIDEO_PLAY };
}

export function pauseVideo() {
    return { type: actionTypes.VIDEO_PAUSE };
}

export function updateProgress(currentPosition) {
    return { type: actionTypes.VIDEO_PROGRESS, currentPosition };
}

export function updateDuration(duration) {
    if (typeof duration !== 'number') {
        duration = parseInt(duration, 10);
    }
    return { type: actionTypes.VIDEO_DURATION, duration };
}

export function mute() {
    return { type: actionTypes.VIDEO_MUTE };
}

export function unmute() {
    return { type: actionTypes.VIDEO_UNMUTE };
}

export function setVolume(volume) {
    return { type: actionTypes.VIDEO_VOLUME, volume };
}

export function setVolumeControlVisibility(isVolumeControlVisible) {
    return { type: actionTypes.SET_VOLUME_CONTROL_VISIBILITY, isVolumeControlVisible };
}

export function enterFullScreen() {
    return { type: actionTypes.VIDEO_ENTER_FULLSCREEN };
}

export function exitFullScreen() {
    return { type: actionTypes.VIDEO_EXIT_FULLSCREEN };
}

export function setStickyMode(isStickyModeEnabled) {
    return { type: actionTypes.SET_STICKY_MODE, isStickyModeEnabled };
}
