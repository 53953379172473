import { h } from 'preact';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import * as loadState from '../../loadState';

import styles from './styles.css';
import loaderIcon from '../../../svg/loader.svg';

const innerHTML = { __html: loaderIcon };

function selector(state) {
    return (
        !state.isThumbnailVisible &&
        !state.isAdsBlocked &&
        (state.isBuffering ||
            state.isAdBuffering ||
            state.isPlaybackInitializing ||
            state.isLoadingSpinnerVisible || // TODO: Remove this at some point. See comments in store.js for more details
            (state.adInitState === loadState.STARTED && !state.isPlaying) ||
            state.videoApiLoadState === loadState.STARTED ||
            state.hlsJsLoadState === loadState.STARTED)
    );
}

const ConnectedLoadingSpinner = () => {
    const showSpinner = useSelector(selector);
    if (!showSpinner) {
        return null;
    }

    return <LoadingSpinner />;
};

export const LoadingSpinner = () => {
    return <div className={cn('video-loading', styles.loader)} dangerouslySetInnerHTML={innerHTML} />;
};

export default ConnectedLoadingSpinner;
