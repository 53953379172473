import { h, Component } from 'preact';
import { connect } from 'react-redux';
import cn from 'classnames';
import { gTimeFormat, createMark } from '../../utils';
import ThumbnailPreview from '../ThumbnailPreview';
import ResponsiveThumbnail from '../ResponsiveThumbnail';
import playOverlaySvg from '../../../svg/play-overlay.svg';
import { getThumbWidth } from '../../thumb-utils';

// styles
import main from './themes/base.css';
import foeTheme from './themes/foe.css';
import wsjArticleTheme from './themes/wsj-article.css';
import mobileTheme from './themes/mobile.css';
import barronsVideoCenter from './themes/barrons-video-center.css';
import barronsNextTheme from './themes/barrons-next.css';
import wsjHomepageTheme from './themes/wsj-home.css';

const onImageLoad = () => createMark('wsj-video-playerThumbDisplayed');

class Thumbnail extends Component {
    constructor(props) {
        super(props);

        let theme = {};
        switch (props.layout) {
            case 'foe':
                theme = foeTheme;
                break;
            case 'wsj-article':
                theme = wsjArticleTheme;
                break;
            case 'mobile':
                theme = mobileTheme;
                break;
            case 'barrons-next':
                theme = barronsNextTheme;
                break;
            case 'barrons-video-center':
                theme = barronsVideoCenter;
                break;
            case 'wsj-home':
                theme = wsjHomepageTheme;
                break;
        }

        this.theme = Object.assign({}, main, theme);
        this.thumbId = `${props.videoId}-video-thumbnail`;

        if (process.env.SSR_MODE !== 'true') {
            // check for existing server rendered img tag
            // use the width and height from the server to pick the image
            // width/height will never match the client so the image always reloads
            const existingImg = document.getElementById(this.thumbId);
            const { width, height } = existingImg?.dataset ?? {};
            if (!isNaN(width)) {
                this.origWidth = parseFloat(width);
            }
            if (!isNaN(height)) {
                this.origHeight = parseFloat(height);
            }
        }
    }

    _renderGradient(hasFlashline) {
        const { layout, suppressHeadline } = this.props;
        if (layout !== 'mobile' && layout !== 'wsj-article' && layout !== 'foe') {
            // no gradient for this layout
            return null;
        }

        if (layout === 'foe' && suppressHeadline) {
            // no gradient if suppressHeadline is true for FOE
            return null;
        }

        // if there is a flashline then we need the gradient on the top and bottom
        return <div className={hasFlashline ? this.theme.gradientTop : this.theme.gradient}></div>;
    }

    _renderHeadline() {
        const { suppressHeadline, layout, width, videoId } = this.props;
        if (suppressHeadline || layout === 'mobile' || layout === 'wsj-article') {
            return null;
        }

        const customThumbTextStyles = {};
        if (width <= 470) {
            customThumbTextStyles.fontSize = '15px';
            customThumbTextStyles.lineHeight = '22px';
        }

        const TitleTag = layout === 'barrons-next' ? 'h4' : 'span';
        return (
            <div className={this.theme.videoThumbText} style={customThumbTextStyles}>
                <TitleTag className={this.theme.videoTitle} id={`video-thumb-text-${videoId}`}>
                    {this.props.videoData.name}
                </TitleTag>
                <span
                    id={`video-thumb-text-hidden-${videoId}`}
                    className={this.theme.visuallyHidden}
                >{`Play video: ${this.props.videoData.name}`}</span>
            </div>
        );
    }

    _renderFlashLine() {
        const { layout, videoData, showFlashLine } = this.props;
        let content = '';
        if (layout === 'wsj-article' && typeof videoData.name === 'string') {
            content = videoData.name;
        } else if (showFlashLine && typeof videoData.seriesName === 'string') {
            content = `Video | ${videoData.seriesName}`;
        } else {
            return null;
        }

        return <div className={this.theme.videoThumbFlashLine}>{content}</div>;
    }

    _renderCTA() {
        const { layout, videoData } = this.props;

        if (layout === 'wsj-article') {
            let durationText = null;
            if (videoData.state !== 'live' && videoData.duration != 0) {
                durationText = <span>| {gTimeFormat(videoData.duration)}</span>;
            }
            return <div className={this.theme.videoThumbCta}>Play Video {durationText}</div>;
        } else if (layout === 'mobile' || layout === 'wsj-home') {
            return <div className={this.theme.videoThumbCta}>{gTimeFormat(videoData.duration)}</div>;
        } else if (layout === 'foe') {
            if (typeof videoData.seriesName === 'string') {
                return <div className={this.theme.videoThumbCta}>{videoData.seriesName}</div>;
            }
        }

        return null;
    }

    render() {
        if (!this.props.isThumbnailVisible) {
            return null;
        }

        const { videoData, onClick, onHoverChange, currentBreakpoint, height } = this.props;
        const flashline = this._renderFlashLine();
        const hasFlashline = flashline !== null;

        return (
            <div className={this.theme.vidThumb}>
                <div
                    className={this.theme.videoThumbContent}
                    onKeyDown={(e) => {
                        if (e.key === ' ') {
                            e.stopPropagation();
                            e.preventDefault();
                            onClick();
                        }
                    }}
                    onClick={onClick}
                >
                    {this._renderGradient(hasFlashline)}
                    <button
                        aria-label={`Play video`}
                        className={this.theme.videoHint}
                        dangerouslySetInnerHTML={{ __html: playOverlaySvg }}
                        data-testid="video-thumbnail-play-button"
                    ></button>
                    <ResponsiveThumbnail
                        id={this.thumbId}
                        className={cn('video-thumbnail', this.theme.videoThumbImg)}
                        height={this.origHeight ?? height}
                        highPriority
                        onLoad={onImageLoad}
                        videoData={videoData}
                        width={this.origWidth ?? getThumbWidth(currentBreakpoint)}
                    />
                    <div className={this.theme.videoThumbTextGroup}>
                        {this._renderHeadline()}
                        {flashline}
                        {this._renderCTA()}
                    </div>
                    {this.props.thumbPreviewEnabled && (
                        <ThumbnailPreview onClick={onClick} onHoverChange={onHoverChange} />
                    )}
                </div>
            </div>
        );
    }
}

// connect the component to the Redux store
function mapStateToProps(reduxState) {
    return {
        currentBreakpoint: reduxState.currentBreakpoint,
        width: reduxState.width,
        height: reduxState.height,
        isThumbnailVisible: reduxState.isThumbnailVisible,
        videoData: reduxState.videoData
    };
}

const ConnectedThumbnail = connect(mapStateToProps)(Thumbnail);

export default ConnectedThumbnail;
