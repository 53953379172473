import { h, Component } from 'preact';
import { connect } from 'react-redux';
import PlayPauseButton from './Buttons/PlayPauseButton';
import VolumeControl from './Buttons/VolumeControl';
import FullScreenButton from './Buttons/FullScreenButton';
import SaveToQueueButton from './Buttons/SaveToQueueButton';
import TimeDisplay from './TimeDisplay';
import ScrubBar from './ScrubBar';

import ccIcon from '../../../svg/Icons_CC_Inactive.svg';
import shareIcon from '../../../svg/Icons_Share.svg';

const ccHtml = { __html: ccIcon };
const shareHtml = { __html: shareIcon };

class VideoControls extends Component {
    render() {
        const { isMobile, videoData, isAdMode } = this.props;
        const hideScrubBar = videoData.state === 'live' || videoData.state === 'flive' || isAdMode;
        const scrubBarProps = {
            isMobile,
            videoId: this.props.videoId,
            onSeek: this.props.onSeek,
            showControls: this.props.showControls,
            enableScrubPreview: this.props.enableScrubPreview
        };

        const saveToQueueProps = {
            onSaveChange: this.props.onSaveChange,
            saveApiPublication: this.props.saveApiPublication,
            guid: this.props.videoData.guid
        };

        const volumeControlProps = {
            videoId: this.props.videoId,
            onSetVolume: this.props.onSetVolume,
            onMuteUnMute: this.props.onMuteUnMute,
            onVolumeControlVisible: this.props.onVolumeControlVisible
        };

        return (
            <div
                className={`video-controls-background ${
                    this.props.isControlsVisible ? 'video-fade-in' : 'video-fade-out'
                }`}
            >
                <div className={'video-controls'} id={`video-controls-${this.props.videoId}`}>
                    {!hideScrubBar && <ScrubBar {...scrubBarProps} />}
                    <PlayPauseButton onPlayPause={this.props.onPlayPause} />
                    <VolumeControl {...volumeControlProps} />
                    <TimeDisplay videoId={this.props.videoId} />
                    <div class="filler"></div>
                    <div
                        className={'captions-list'}
                        id={`captions-list-${this.props.videoId}`}
                        data-space="false"
                    ></div>
                    <button
                        className={'video-share-b f-r'}
                        id={`video-share-b-${this.props.videoId}`}
                        data-space="false"
                        aria-label="Sharing"
                        aria-haspopup="true"
                        aria-controls={`video-share-${this.props.videoId}`}
                        type="button"
                        data-testid="video-share-button"
                        dangerouslySetInnerHTML={shareHtml}
                    />
                    <button
                        className={'video-cc f-r'}
                        id={`video-cc-${this.props.videoId}`}
                        data-space="false"
                        aria-label="Captions"
                        aria-pressed={this.props.isClosedCaptionsVisible}
                        type="button"
                        data-testid={`video-cc-button-${this.props.isClosedCaptionsVisible ? 'on' : 'off'}`}
                        dangerouslySetInnerHTML={ccHtml}
                    />
                    {this.props.saveToQueueEnabled && <SaveToQueueButton {...saveToQueueProps} data-space="false" />}
                    <FullScreenButton
                        videoId={this.props.videoId}
                        onToggleFullScreen={this.props.onToggleFullScreen}
                        data-space="false"
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(reduxState) {
    return {
        isControlsVisible: reduxState.isControlsVisible,
        videoData: reduxState.videoData,
        isAdMode: reduxState.isAdMode,
        isPlaying: reduxState.isPlaying,
        isVolumeControlVisible: reduxState.isVolumeControlVisible,
        isClickForSoundVisible: reduxState.isClickForSoundVisible,
        isClosedCaptionsVisible: reduxState.isClosedCaptionsVisible,
        isSharescreenVisible: reduxState.isSharescreenVisible
    };
}

const ConnectedVideoControls = connect(mapStateToProps)(VideoControls);

export default ConnectedVideoControls;
