export function addPageAction(action: string, data?: any): void {
    if (typeof newrelic === 'object' && typeof newrelic.addPageAction === 'function') {
        newrelic.addPageAction(action, data);
    }
}

export function setCustomAttribute(key: string, value: any): void {
    if (typeof newrelic === 'object' && typeof newrelic.setCustomAttribute === 'function') {
        newrelic.setCustomAttribute(key, value);
    }
}

export function getEventDuration(measureName: string, startMark?: string, endMark?: string): number {
    try {
        return performance.measure(measureName, startMark || `${measureName}:start`, endMark || `${measureName}:end`)
            .duration;
    } catch (e) {
        return -1;
    }
}
