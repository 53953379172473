import { h, Component } from 'preact';
import { connect } from 'react-redux';

const fullScreenIcon =
    '<svg width="22px" height="22px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons/Full-Screen/Inactive" fill="#FFFFFF" fill-rule="nonzero"><path d="M21.3333333,19.5555556 L24,16.8888889 L24,24 L16.8888889,24 L19.5555556,21.3333333 L16,17.7777778 L17.7777778,16 L21.3333333,19.5555556 Z M19.5555556,6.66666667 L16.8888889,4 L24,4 L24,11.1111111 L21.3333333,8.44444444 L17.7777778,12 L16,10.2222222 L19.5555556,6.66666667 Z M8.44444444,21.3333333 L11.1111111,24 L4,24 L4,16.8888889 L6.66666667,19.5555556 L10.2222222,16 L12,17.7777778 L8.44444444,21.3333333 Z M6.66666667,8.44444444 L4,11.1111111 L4,4 L11.1111111,4 L8.44444444,6.66666667 L12,10.2222222 L10.2222222,12 L6.66666667,8.44444444 Z" id="Full-Screen"></path></g></g></svg>';
const exitFullScreenIcon =
    '<svg width="22px" height="22px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons/Full-Screen/Active" fill="#FFFFFF" fill-rule="nonzero"><path d="M8.33333333,6.55555556 L11,3.88888889 L11,11 L3.88888889,11 L6.55555556,8.33333333 L3,4.77777778 L4.77777778,3 L8.33333333,6.55555556 Z M6.55555556,19.6666667 L3.88888889,17 L11,17 L11,24.1111111 L8.33333333,21.4444444 L4.77777778,25 L3,23.2222222 L6.55555556,19.6666667 Z M21.4444444,8.33333333 L24.1111111,11 L17,11 L17,3.88888889 L19.6666667,6.55555556 L23.2222222,3 L25,4.77777778 L21.4444444,8.33333333 Z M19.6666667,21.4444444 L17,24.1111111 L17,17 L24.1111111,17 L21.4444444,19.6666667 L25,23.2222222 L23.2222222,25 L19.6666667,21.4444444 Z" id="Full-Screen"></path></g></g></svg>';

class FullScreenButton extends Component {
    shouldComponentUpdate(nextProps) {
        // only re render when these props change
        return this.props.isFullScreen !== nextProps.isFullScreen;
    }

    onKeyDown(e) {
        if (e.key === ' ') {
            e.stopPropagation();
            this.props.onToggleFullScreen();
        }
    }

    render() {
        const { isFullScreen, onToggleFullScreen } = this.props;
        const icon = isFullScreen ? exitFullScreenIcon : fullScreenIcon;
        return (
            <button
                className={'video-fullscreen f-r'}
                id={`video-fullscreen-${this.props.videoId}`}
                aria-pressed={isFullScreen}
                aria-label="Fullscreen"
                dangerouslySetInnerHTML={{ __html: icon }}
                onKeyDown={this.onKeyDown.bind(this)}
                onClick={onToggleFullScreen}
                data-testid="video-fullscreen-button"
            />
        );
    }
}

function mapStateToProps(reduxState) {
    return {
        isFullScreen: reduxState.isFullScreen
    };
}

const ConnectedFullScreenButton = connect(mapStateToProps)(FullScreenButton);

export default ConnectedFullScreenButton;
